  .quadmetcs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 180px;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .quads {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .metcs {
    height: 150px;
    width: 150px;
    margin-bottom: 10px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  h3 {
    color: #5A5C60;
    text-align: center;
  }

  @media screen and (max-width:500px) {
    .quadmetcs {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;
      padding: 10px;
    }
    .metcs {
      height: 80px;
      width: 80px;
      margin-bottom: 10px;
      object-fit: cover;
      border-radius: 10px;
    }
    h3 {
      color: #5A5C60;
      text-align: left;
      font-size: 12px;
    }
  }