.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 100%;
    margin: 20px auto;
    height: 100%;
    background-color: #101728;
}

.title {
    color: #2EB4B3;
    margin: 0;
    padding: 40px 0;
}

.info p {
    color: #D7D8DA;
    font-weight: bold;
    width: 100%;
    max-width: 450px;
    padding: 30px 0;
}

 .info p .colored {
    color: #2EB4B3;
}

.image {
    height: 400px;
    width: 400px;
    margin-right: 20px;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 20px;
    padding-left: 60px;
}

   .button {
    width: 170px;
    height: 60px;
    border-radius: 40px;
    font-weight: bold;
    background: linear-gradient(145deg, #231F20, #75BFC0);
    padding: 10px;
    border: none;
    color: #fff;
    font-size: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    margin: 10px;
    bottom: 10px;
    right: 10px;
   }

   .button:hover {
    background-color: #2EB4B3; /* cor de hover */
    transform: scale(1.05);
  }

  @media screen and (max-width:500px) {
    .container {
        display: flex;
        align-items: left;
        justify-content: space-between;
        flex-direction: column;
        padding: 20px;
        max-width: 100%;
        margin: 20px auto;
        height: 100%;
        background-color: #101728;
    }
    .title {
        color: #2EB4B3;
        margin: 0;
        padding: 15px 0;
        font-size: 18px;
    }
    .info p {
        color: #D7D8DA;
        font-weight: bold;
        width: 100%;
        max-width: 300px;
        padding: 30px 0;
        font-size: 14px;
    }
    .button {
        width: 120px;
        height: 40px;
        border-radius: 12px;
        font-weight: bold;
        background: linear-gradient(145deg, #231F20, #75BFC0);
        padding: 10px;
        border: none;
        color: #fff;
        font-size: 20px;
        transition: background-color 0.3s ease, transform 0.3s ease;
        cursor: pointer;
        margin: 10px;
        bottom: 10px;
        right: 10px;
        font-size: 14px;
       }
    
       .button:hover {
        background-color: #2EB4B3; 
        transform: scale(1.05);
      }
      .image {
        height: 300px;
        width: 300px;
        flex-direction: flex-start;
        align-items: center;
        text-align: left;
      }
  }