.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 80%; /* largura consistente */
    margin: 60px auto;/* centraliza o conteúdo */
  }
  
  .info {
    flex: 1;
    padding-right: 20px;
    text-align: left;
    max-width: 600px;
  }

  .info h1 {
    color: #2EB4B3;
    padding: 10px;
  }

  .info h3 {
    color: #5A5C60;
    padding: 10px;
    text-align: left;
  }

  .info p {
    color: #5A5C60;
    font-weight: bold;
    padding: 10px;
  }
  
  .imagecontainer {
    position: relative;
    height: 440px;
    width: 440px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .imagecontainer::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    border-radius: 50%;
    filter: blur(20px);
    background-color: #2EB4B3;
    z-index: -1;
    opacity: 0.4;
    box-shadow: 10px 10px 20px rgba(192, 247, 248, 0.8);
  }
  
  .image {
    height: 380px;
    width: 380px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }

  .button {
    width: 170px;
    height: 60px;
    border-radius: 40px;
    font-weight: bold;
    background: linear-gradient(145deg, #231F20, #75BFC0);
    padding: 10px;
    border: none;
    color: #fff;
    font-size: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    margin: 10px;
  }

  .button:hover {
    background-color: #2EB4B3; /* cor de hover */
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    .container {
        flex-direction: column; /* Altera para layout de coluna em telas menores */
        align-items: center;
        text-align: left;
        width: 90%; 
        margin: 40px auto; 
        padding: 10px; 
    }

    .info {
        max-width: 100%; 
        padding: 10px;
        text-align: center; 
    }

    .info h1 {
        padding: 8px;
        text-align: left;
    }

    .info h3, .info p {
        padding: 8px;
        text-align: left;
    }

    .imagecontainer {
        height: 300px; 
        width: 300px; 
        margin-bottom: 20px; 
    }

    .imagecontainer::before {
        top: -10px; 
        left: -10px;
        right: -10px;
        bottom: -10px;
        filter: blur(10px); 
        box-shadow: 5px 5px 10px rgba(192, 247, 248, 0.8); 
    }

    .image {
        height: 240px; 
        width: 240px; 
    }

    .button {
      width: 170px;
      height: 60px;
      border-radius: 40px;
      font-weight: bold;
      background: linear-gradient(145deg, #231F20, #75BFC0);
      padding: 10px;
      border: none;
      color: #fff;
      font-size: 20px;
      transition: background-color 0.3s ease, transform 0.3s ease;
      cursor: pointer;
      margin: 10px;
    }
    .button:hover {
      background-color: #2EB4B3; /* cor de hover */
      transform: scale(1.05);
    }
}
