/* Navbar CSS */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
  background-color: #fff;
  position: relative;
  width: 80%;
  margin: 0 auto;
  transition: height 0.3s ease, padding 0.3s ease;
}

.navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Ajuste a altura conforme necessário */
  background-color: #ffffff; /* Cor de fundo */
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
  transition: height 0.7s ease, padding 0.5s ease;
}

.image {
  height: 250px; /* Altura da imagem no estado normal */
  width: 250px; /* Largura da imagem no estado normal */
  transition: height 0.3s ease, width 0.3s ease;
}

.navbar.fixed .image {
  height: 40px; /* Altura da imagem quando o navbar está fixo */
  width: 40px; /* Largura da imagem quando o navbar está fixo */
}

.ancora {
  color: #5a5c60;
  text-decoration: none;
  padding: 2px;
  margin: 0 40px;
  transition: color 0.3s ease, margin 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.ancora:hover {
  color: #2eb4b3;
  transform: scale(1.05);
}

.navbar.fixed .ancora {
  margin: 0 20px; /* Ajuste a margem conforme necessário apenas quando o Navbar está fixo */
}

.navbar::after {
  content: '';
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-bottom: 4px solid #2eb4b3;
}

/* Remover o scroll lateral */
body {
  overflow-x: hidden;
}

/* Compensar a altura do navbar fixo */
body.fixed-navbar {
  padding-top: 60px; /* Deve ser igual à altura do navbar.fixed */
}

/* Estilos para dispositivos móveis */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: row-reverse; /* Inverte a direção para colocar o menu à direita */
    align-items: center;
  }

  .image {
    height: 170px;
    width: 130px;
    margin-right: auto; /* Alinha a logo à esquerda */
    order: 1; /* Logo no canto esquerdo */
  }

  .ancora {
    width: auto; /* Volta ao tamanho automático */
    margin: 5px 10px; /* Espaçamento entre os links */
    text-align: left; /* Alinha o texto à esquerda */
    order: 2; /* Links no canto direito */
  }

  .navbar::after {
    width: 100%; /* Ajusta a largura da linha para ocupar toda a largura */
  }

  /* Estilos do menu hambúrguer */
  .menuIcon {
    cursor: pointer;
    display: block; /* Exibe o ícone do hambúrguer em telas menores */
    margin-left: 20px; /* Espaçamento do ícone em relação à esquerda */
    order: 3; /* Ícone do menu hambúrguer no canto direito */
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #5A5C60;
    margin: 5px 0;
    transition: background-color 0.3s ease;
  }

  .bar:hover {
    background-color: #2EB4B3; /* Cor das barras do menu hambúrguer quando ativado */
  }

  .menuIcon.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menuIcon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menuIcon.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 10px;
    z-index: 1000;
    width: 200px; /* Largura do menu dropdown */
  }

  .menu.active {
    display: flex; /* Exibe o menu quando ativo */
  }

  .menu a {
    color: #5A5C60;
    text-decoration: none;
    padding: 10px;
    margin: 5px 0;
    transition: color 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left; /* Alinha o texto à esquerda no menu */
  }

  .menu a:hover {
    color: #2EB4B3;
  }
}



