/* Servicos.module.css */

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #101728;
    height: 1600px;
  }
  
  .title {
    color: #2eb4b3;
    font-weight: bold;
    border-bottom: 4px solid #2eb4b3;
    width: 80%;
    margin: 40px auto 20px auto;;
    padding: 20px;
    top: 80px;
    align-items: center;
    text-align: center;
  }
  
  .type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
    margin-top: 20px;
    width: 70%;
    position: relative;    
    font-size: 20px;
    color: #2eb4b3;
  }

  .smart {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    width: 80%;
    transition: transform 0.5s ease;
  }

  .smart:hover {
    transform: scale(1.05); 
  }

  .smartpd1,
  .smartpd2,
  .datapd1,
  .datapd2,
  .solutionspd1,
  .solutionspd2 {
      width: 400px;
      height: 250px;
      background-color: #D7D8DA;
      border-radius: 17px;
      padding: 10px;
      margin: 10px;
      position: relative;
  }
  
  .smartpd2,
  .datapd1,
  .solutionspd2 {
      background-color: #FFFFFF;
  }
  
  .smartpd1 h3,
  .smartpd2 h3,
  .datapd1 h3,
  .datapd2 h3,
  .solutionspd1 h3,
  .solutionspd2 h3 {
      margin: 10px;
      padding: 10px;
      color: #2eb4b3;
      font-size: 16px;
      text-align: left;
  }
  
  .smartpd1 p,
  .smartpd2 p,
  .datapd1 p,
  .datapd2 p,
  .solutionspd1 p,
  .solutionspd2 p {
      margin: 10px;
      padding: 10px;
      color: #5A5C60;
      font-weight: bold;
      font-size: 14px;
  }
  

   .button {
    width: 170px;
    height: 60px;
    border-radius: 40px;
    font-weight: bold;
    background: linear-gradient(145deg, #231F20, #75BFC0);
    padding: 10px;
    border: none;
    color: #fff;
    font-size: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    margin: 20px;
    bottom: -100px;
    right: 20px;
    position: absolute;
   }

   .button:hover {
    background-color: #2EB4B3; /* cor de hover */
    transform: scale(1.05);
  }
  
  
   .smart-item {
    width: calc(50% - 20px); /* Ajuste para 2 colunas com espaço entre */
    margin-bottom: 20px;
    position: relative;
  }
  
  .button-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .smartpd1 img {
    display: flex;
    align-items: left;
    height: 60px;
    width: 60px;
  }

  .smartpd2 img{
    display: flex;
    align-items: left;
    height: 60px;
    width: 60px;
  }
  

  .datapd1 img {
    display: flex;
    align-items: left;
    height: 60px;
    width: 60px;
  }

  .datapd2 img {
    display: flex;
    align-items: left;
    height: 60px;
    width: 60px;
  }

  .solutionspd1 img {
    display: flex;
    align-items: left;
    height: 60px;
    width: 60px;
  }

  .solutionspd2 img {
    display: flex;
    align-items: left;
    height: 60px;
    width: 60px;
  }

  @media screen and (max-width:500px) {
    .title {
      color: #2eb4b3;
      font-weight: bold;
      border-bottom: 4px solid #2eb4b3;
      width: 80%;
      margin: 20px;
      padding: 20px;
      align-items: center;
      text-align: center;
    }
    .type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 20px;
      font-size: 16px;
      width: 80%;
      position: relative;    
      font-size: 12px;
      color: #2eb4b3;
    }
    .smartpd1,
    .smartpd2,
    .datapd1,
    .datapd2,
    .solutionspd1,
    .solutionspd2 {
      width: 300px;
      height: 170px;
      background-color: #D7D8DA;
      border-radius: 14px;
      padding: 10px;
      margin: 10px;
      position: relative;
    }
    .smartpd1 p,
    .smartpd2 p,
    .datapd1 p,
    .datapd2 p,
    .solutionspd1 p,
    .solutionspd2 p {
      margin: 10px;
      padding: 10px;
      color: #5A5C60;
      font-weight: bold;
      font-size: 10px;
    }

    .smartpd1 h3,
    .smartpd2 h3,
    .datapd1 h3,
    .datapd2 h3,
    .solutionspd1 h3,
    .solutionspd2 h3 {
      margin: 2px;
      padding: 2px;
      color: #2eb4b3;
      font-size: 12px;
      text-align: left;
    }
    .button:hover {
      background-color: #2EB4B3; /* cor de hover */
      transform: scale(1.05);
    }
    .smartpd1 img {
      display: flex;
      align-items: left;
      height: 20px;
      width: 20px;
    }
  
    .smartpd2 img{
      display: flex;
      align-items: left;
      height: 20px;
      width: 20px;
    }
    
  
    .datapd1 img {
      display: flex;
      align-items: left;
      height: 20px;
      width: 20px;
    }
  
    .datapd2 img {
      display: flex;
      align-items: left;
      height: 20px;
      width: 20px;
    }
  
    .solutionspd1 img {
      display: flex;
      align-items: left;
      height: 20px;
      width: 20px;
    }
  
    .solutionspd2 img {
      display: flex;
      align-items: left;
      height: 20px;
      width: 20px;
    }

    .smartpd1 p,
    .smartpd2 p,
    .datapd1 p,
    .datapd2 p,
    .solutionspd1 p,
    .solutionspd2 p {
      margin: 3px;
      padding: 3px;
      color: #5A5C60;
      font-weight: bold;
      font-size: 10px;
    }
    .button {
      width: 100px;
      height: 30px;
      border-radius: 10px;
      font-weight: bold;
      background: linear-gradient(145deg, #231F20, #75BFC0);
      padding: 5px;
      border: none;
      color: #fff;
      font-size: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease;
      cursor: pointer;
      margin: 20px;
      bottom: -60px;
      right: 20px;
      position: absolute;
     }
  
     .button:hover {
      background-color: #2EB4B3; /* cor de hover */
      transform: scale(1.05);
    }
    .type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 20px;
      margin-top: 40px;
      width: 70%;
      position: relative;    
      font-size: 14px;
      color: #2eb4b3;
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      background-color: #101728;
      height: 1700px;
    }
  }