.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 100%;
}

.content {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.logoContainer {
  margin-right: 20px; /* Espaçamento entre a logo e o conteúdo */
}

.image {
  height: 350px;
  width: 350px;
  margin-right: 40px; /* Ajuste conforme necessário */
  margin-bottom: 40px;
  object-fit: cover;
  border-right: 4px solid #2EB4B3; /* Adiciona a borda */
}

.info {
  flex: 1; /* Ocupa o espaço restante */
  display: flex;
  flex-direction: column;
}

.info p {
  color: #5A5C60;
  font-weight: bold;
  width: 100%;
  max-width: 430px;
  padding: 10px;
  margin: 0; /* Remove margens para evitar espaçamento extra */
}

.info p span {
  color: #2EB4B3;
  margin-left: auto; /* Alinha o span à direita */
}

.button {
  width: 170px;
  height: 60px;
  border-radius: 40px;
  font-weight: bold;
  background: linear-gradient(145deg, #231F20, #75BFC0);
  padding: 10px;
  border: none;
  color: #fff;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  margin: 10px;
  bottom: 10px;
  right: 10px;
}

.button:hover {
  background-color: #2EB4B3;
  transform: scale(1.05);
}
.mapMarkerIcon {
  color: #2EB4B3; /* Define a cor do ícone */
  margin-right: 10px; /* Adiciona um espaçamento à direita do ícone, se necessário */
}
.mapContainer {
  width: 350px;
  height: 350px;
}

@media only screen and (max-width: 480px) {
  .container {
    padding: 10px;
  }
  .content {
    flex-direction: column;
  }
  .image {
    height: 150px;
    width: 150px;
    margin-right: 30px;
    margin-bottom: 5px;
  }
  .info {
    flex-direction: column;
  }
  .info p {
    font-size: 12px;
    max-width: 250px;
  }
  .button {
    width: 100px;
    height: 40px;
    font-size: 10px;
  }
  .mapContainer {
    width: 100%; /* Make it full-width */
    height: 200px; /* Adjust the height as needed */
    margin-top: 20px; /* Add some margin to separate from above elements */
    display: flex; /* Add this to center the map horizontally */
    justify-content: center; /* Add this to center the map horizontally */
  }
  .mapContainer > div { /* Target the inner div of the MapContainer */
    width: 100%!important; /* Make the map container full-width */
    height: 100%!important; /* Make the map container full-height */
    margin-top: -80px;
    margin-right: 10px;
  }
}