/* Sobrenos.module.css */

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 1200px;
    margin: 20px auto;
  }
  
  .image {
    height: 350px;
    width: 350px;
    margin-right: 20px; 
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 20px; 
  }
  
  .divider {
    border-left: 4px solid #2EB4B3;
    height: 400px;
    margin: 0 20px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    margin-top: -100px;
  }
  
  .title {
    color: #2EB4B3;
    margin: 0;
    padding: 10px 0; 
  }
  
  .info {
    padding: 10px 0;
  }
  
  .info p {
    color: #5A5C60;
    font-weight: bold;
    width: 100%;
    max-width: 550px;
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
      padding: 10px;
      width: 90%;
      margin: 0 auto;
    }
    .image {
      height: 250px;
      width: 250px;
      margin-right: 20px; 
      margin-bottom: -250px;
      margin-top: 20px;
      object-fit: cover;
      border-radius: 20px; 
      align-items: flex-end;
    }

    .divider {
      border-left: none;
      padding: 0 auto;
      margin: 0 auto;
    }
    .title {
      color: #2EB4B3;
      margin: 0;
      padding: 10px 0; 
      font-size: 16px;
    }
    .info p {
      color: #5A5C60;
      font-weight: bold;
      width: 100%;
      max-width: 450px;
      font-size: 12px;   
    }
  }
  