* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body, html {
  overflow-x: hidden; /* Impede o scroll horizontal */
}

.App {
  overflow-x: hidden; /* Garante que a classe App não cause scroll horizontal */
  min-height: 100vh; /* Garante altura mínima de viewport */
}

